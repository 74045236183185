import React, { useState, useEffect, useRef } from "react";
import * as Easing from "js-easing-functions";
import "./App.css";

const STEPS = {
  HOLD: "HOLD",
  IN: "IN",
  OUT: "OUT"
};

const BREATH_STEPS = [STEPS.IN, STEPS.HOLD, STEPS.OUT, STEPS.HOLD];

function useSteps(steps) {
  const [stepLength, setStepLength] = useState(3.5);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepProgress, setStepProgress] = useState(0);

  const stepLengthInMS = stepLength * 1000;

  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      setStepProgress(prev => prev + deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  useEffect(() => {
    setStepProgress(0);
  }, [stepIndex, stepLength]);

  useEffect(() => {
    setStepIndex(0);
  }, [stepLength]);

  useEffect(() => {
    const id = setTimeout(
      () => setStepIndex((stepIndex + 1) % steps.length),
      stepLengthInMS
    );
    return () => clearTimeout(id);
  }, [steps.length, stepIndex, stepLengthInMS]);

  return [
    {
      step: steps[stepIndex],
      stepIndex,
      stepLength,
      stepPercentage: (stepProgress / stepLengthInMS) * 100
    },
    { setStepLength }
  ];
}

function transform(index, progress) {
  switch (index) {
    case 0:
      return `translate(0, -${progress}%)`;
    case 1:
      return `translate(${progress}%, -100%)`;
    case 2:
      return `translate(100%, ${progress - 100}%)`;
    case 3:
      return `translate(${100 - progress}%, 0%)`;
    default:
      return `none`;
  }
}

function lungTransform(step, progress) {
  const scale = progress => {
    const easedProgress = Easing.easeInOutCubic(progress, 0, 100, 100);
    return `scale(${0.5 + easedProgress * 0.003}, ${0.5 +
      easedProgress * 0.003})`;
  };
  switch (step) {
    case 0:
      return scale(progress);
    case 1:
      return scale(100);
    case 2:
      return scale(100 - progress);
    case 3:
      return scale(0);
    default:
      return `none`;
  }
}

function App() {
  const [
    { stepPercentage, stepIndex, stepLength },
    { setStepLength }
  ] = useSteps(BREATH_STEPS);

  const translation = transform(stepIndex, stepPercentage);

  return (
    <div className="App">
      <div className="Breath">
        <input
          className="Breath__Input"
          value={stepLength}
          step={0.5}
          type="number"
          onChange={({ currentTarget: { value } }) => setStepLength(value)}
        />

        {BREATH_STEPS.map((step, key) => (
          <div className="Breath__Label" key={key}>
            {step}
          </div>
        ))}

        {stepLength && (
          <>
            <div
              className="Breath__Cursor"
              style={{ transform: translation }}
            />
            <svg
              style={{ transform: lungTransform(stepIndex, stepPercentage) }}
              className="Breath__Icon"
              filter="url(#goo)"
              viewBox="0 0 500 500"
              width="100px"
              height="100px"
            >
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={12}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                    result="goo"
                  />
                  <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
              </defs>
              <g>
                <ellipse>
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="360 250 250"
                    to="0 250 250"
                    dur="40s"
                    repeatCount="indefinite"
                  />
                </ellipse>
                <ellipse>
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 250 250"
                    to="360 250 250"
                    dur="40s"
                    repeatCount="indefinite"
                  />
                </ellipse>
                <ellipse>
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 250 250"
                    to="360 250 250"
                    dur="40s"
                    repeatCount="indefinite"
                  />
                </ellipse>
                <ellipse>
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="360 250 250"
                    to="0 250 290"
                    dur="40s"
                    repeatCount="indefinite"
                  />
                </ellipse>
                <ellipse>
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="360 300 250"
                    to="0 250 200"
                    dur="40s"
                    repeatCount="indefinite"
                  />
                </ellipse>
              </g>
            </svg>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
